import { Box } from '@chakra-ui/react'
import { FormikValues } from 'formik'
import { useEffect, useState } from 'react'
import { HFlex, PaymentFormData, VFlex } from 'ui'
import { BraintreeContainer } from 'ui/src/components/v3/BraintreeCardDetailsForm'
import { useScript } from 'usehooks-ts'

type PaytheoryCardDetailsProps<T extends FormikValues> = {
  setFieldValue: (field: string, value: boolean) => void
  onPaytheoryLoad?: (paytheoryClient: any) => void
  sdkUrl: string
  apiKey: string
}

const BORDER_COLOR = '#e5e5e5'
const BORDER_STYLE = `1px solid ${BORDER_COLOR}`
const GRAY_800 = '#292524'

declare global {
  interface Window {
    paytheory: any
  }
}

export function PaytheoryCardDetails({
  setFieldValue,
  onPaytheoryLoad,
  sdkUrl,
  apiKey,
}: PaytheoryCardDetailsProps<PaymentFormData>) {
  const status = useScript(sdkUrl)

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (window.paytheory) {
      setLoading(false)
      window.paytheory.errorObserver((error: any) => {
        console.log(error)
      })
      window.paytheory.readyObserver((ready: any) => {})
      window.paytheory.validObserver((valid: any) => {
        setFieldValue('paymentMethodDetails.creditCardCVV', true)
        setFieldValue('paymentMethodDetails.creditCardNumber', true)
        setFieldValue('paymentMethodDetails.creditCardExpirationDate', true)
      })
      window.paytheory.payTheoryFields({
        apiKey: apiKey,
        styles: {
          default: {
            color: GRAY_800,
            fontSize: '16px',
            fontWeight: 300,
            fontFamily: 'Inter',
            'padding-left': '16px',
          },
          error: {
            color: 'red',
            fontSize: '16px',
            fontWeight: 300,
            fontFamily: 'Inter',
            'padding-left': '16px',
          },
          success: {
            color: GRAY_800,
            fontSize: '16px',
            fontWeight: 300,
            fontFamily: 'Inter',
            'padding-left': '16px',
          },
        },
      })
      onPaytheoryLoad && onPaytheoryLoad(window.paytheory)
    }
  }, [status])

  return (
    <Box w="100%">
      <VFlex>
        <BraintreeContainer
          id="pay-theory-credit-card-number"
          borderTopRightRadius="5px"
          borderTopLeftRadius="5px"
          borderBottomLeftRadius="0"
          borderBottomRightRadius="0"
          overflow="hidden"
          border={BORDER_STYLE}
          width="100%"
          invalid={false}
          loading={loading}
        />
        <HFlex>
          <BraintreeContainer
            id="pay-theory-credit-card-exp"
            borderLeft={BORDER_STYLE}
            borderRight={BORDER_STYLE}
            borderBottom={BORDER_STYLE}
            borderBottomLeftRadius="5px"
            overflow="hidden"
            invalid={false}
            loading={loading}
          />
          <BraintreeContainer
            id="pay-theory-credit-card-cvv"
            borderTop="0"
            borderBottom={BORDER_STYLE}
            borderRight={BORDER_STYLE}
            borderBottomRightRadius="5px"
            overflow="hidden"
            invalid={false}
            loading={loading}
          />
        </HFlex>
      </VFlex>
    </Box>
  )
}
