import {
  FormControl,
  FormErrorMessage,
  Text,
  Modal,
  useDisclosure,
  ModalBody,
  ModalContent,
  Flex,
  ModalOverlay,
  ModalHeader,
  HStack,
  PinInput,
  PinInputField,
  Spinner,
  Center,
  useToast,
  ModalCloseButton,
  Box,
  VStack,
} from '@chakra-ui/react'
import {
  SelfDocument,
  SendNickelVerificationCodeViaEmailDocument,
  ValidateEmailVerificationCodeDocument,
} from '../operations-types'
import { Form, Button } from 'ui'
import { useState, useRef } from 'react'
import { useMutation } from '@apollo/client'
import * as yup from 'yup'
import { useDashboardOutletContext } from '../lib/outletContext'

type SendCodeComponentProps = {
  identifier?: string
  successfulValidation?: boolean
  disabled: boolean
  setDisabled: (disabled: boolean) => void
  validateVerificationCode(identifier: string, code: string): void
  sendVerificationCode(identifier: string): void
  verificationCodeSending?: boolean
  error?: string
  setSentCode: (sentCode: boolean) => void
  enrollment?: boolean
}

export const SendCodeComponent = (props: SendCodeComponentProps) => {
  const baseInputRef = useRef(null)
  return (
    <Flex gap="4" flexDirection="column" w="100%">
      <HStack spacing="0" gap="0">
        <Text size="sm" className="leading-normal !text-sm">
          Please enter the 6-digit code sent to{' '}
          {!props.enrollment ? 'your email address on file.' : ''}
        </Text>
        <Text fontWeight="semibold" size="sm" ps="1">
          {!!props.identifier && !!props.enrollment ? props.identifier : ''}
        </Text>
      </HStack>

      <HStack w="100%">
        <PinInput
          size={'lg'}
          autoFocus
          isDisabled={props.disabled}
          isInvalid={props.successfulValidation}
          onComplete={(value) => {
            props.setDisabled(true)
            props.validateVerificationCode(value, props.identifier || '')
            props.setDisabled(false)
          }}
        >
          <PinInputField data-cy="pin-input" />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField ref={baseInputRef} />
        </PinInput>
      </HStack>
      <FormControl id="code" w="100%" isInvalid={!!props.error}>
        {props.error ? <FormErrorMessage>{props.error}</FormErrorMessage> : ''}
      </FormControl>

      <Text size="small" className="leading-normal !text-sm" w="100%">
        Didn&apos;t receive a code?
        {props.verificationCodeSending ? (
          <Spinner size={'xs'} className="relative left-[5px] top-[2px]" />
        ) : (
          <a
            onClick={() => {
              props.sendVerificationCode(props.identifier || '')
            }}
            className="font-semibold px-2 cursor-pointer underline"
          >
            Resend
          </a>
        )}
      </Text>
    </Flex>
  )
}

const TwoFactorEmailAuthSchema = yup.object().shape({
  code: yup.string().required(),
})

export type TwoFactorAuthCardProps = {
  onClose?: () => void
  setIsCompleted: (isCompleted: boolean) => void
  enrollment?: boolean
  sentEmailCode?: boolean
} & EmailTwoFactorModalProps
const TwoFactorAuthCard = (props: TwoFactorAuthCardProps) => {
  const data = useDashboardOutletContext()
  const email = data?.email

  const toaster = useToast()
  const [sendTwoFactorCode] = useMutation(
    SendNickelVerificationCodeViaEmailDocument,
    {
      onError: (e) => {
        setError(e.message)
        toaster({
          status: 'error',
          title: 'Error sending one-time code.',
        })
      },
    },
  )

  const [verifyTwoFactorCode] = useMutation(
    ValidateEmailVerificationCodeDocument,

    {
      onCompleted: async (e) => {
        if (e.validateEmailVerificationCode?.data?.success === true) {
          await setSuccessfulValidation(true)
          await props.setIsCompleted(true)
          if (!props.enrollment) {
            props.twoFactorSuccessAction &&
              e.validateEmailVerificationCode?.data?.encryptionKey &&
              (await props.twoFactorSuccessAction(
                e.validateEmailVerificationCode?.data?.encryptionKey,
              ))
          } else {
            props.twoFactorSuccessAction &&
              (await props.twoFactorSuccessAction(''))
          }
        } else {
          setError('Invalid code entered.')
        }
      },
      onError: (e) => {
        setError(e.message)
        toaster({
          status: 'error',
          title: 'Error verifying one-time code.',
        })
      },
      refetchQueries: [{ query: SelfDocument }],
    },
  )

  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [sentCode, setSentCode] = useState<boolean>(
    props.sentEmailCode || false,
  )

  const [successfulValidation, setSuccessfulValidation] =
    useState<boolean>(false)
  return (
    <>
      {!sentCode && (
        <Text>
          We need to confirm your identity first. Click to receive a 6-digit
          code to your registered email.
        </Text>
      )}

      <Form
        {...{
          className: 'w-full',
          validationSchema: TwoFactorEmailAuthSchema,
          initialValues: {
            code: '',
          },
          onSubmit: async (values, actions) => {
            setLoading(true)

            await verifyTwoFactorCode({
              variables: { code: values.code, email: email! },
            })

            setLoading(false)
            actions.setSubmitting(false)
          },
        }}
      >
        {(formik) => (
          <Flex flexDirection="column" w="100%" gap="2">
            {!sentCode ? (
              <Center>
                <Flex direction="row" align="end" w="100%" gap="5">
                  {!!props.enrollment && <Box pt="2" w="100%"></Box>}

                  <Button
                    w="100%"
                    h="41.6px"
                    label="Send code"
                    isLoading={loading}
                    onClick={async () => {
                      setLoading(true)
                      setSentCode(true)

                      await sendTwoFactorCode()
                      setLoading(false)
                    }}
                  />
                  <FormControl id="code" w="100%" isInvalid={!!error}>
                    {error ? <FormErrorMessage>{error}</FormErrorMessage> : ''}
                  </FormControl>
                </Flex>
              </Center>
            ) : !successfulValidation ? (
              <Flex direction={{ md: 'column' }} align="end" w="100%" gap="5">
                <SendCodeComponent
                  {...{
                    identifier: email!,
                    disabled: loading,
                    setDisabled: setLoading,
                    error: error || '',
                    setSentCode: setSentCode,
                    enrollment: props.enrollment,
                    validateVerificationCode: async (
                      code: string,
                      identifier: string,
                    ) => {
                      await verifyTwoFactorCode({
                        variables: { code: code, email: email! },
                      })
                    },
                    sendVerificationCode: async (identifier: string) => {
                      try {
                        await sendTwoFactorCode()
                      } catch (e: any) {
                        setError(e.message)
                      }
                    },
                  }}
                />
              </Flex>
            ) : !!props.enrollment ? (
              <VStack gap="5">
                <Text>
                  Success! You are enrolled in two-factor authentication [2FA].
                  To update your verified phone number, go to the Settings page.
                </Text>

                <Button
                  label="Continue"
                  w="100%"
                  onClick={() => {
                    props.twoFactorSuccessAction &&
                      props.twoFactorSuccessAction('')
                  }}
                />
              </VStack>
            ) : (
              ''
            )}
          </Flex>
        )}
      </Form>
    </>
  )
}

type EmailTwoFactorModalProps = {
  enrollment?: boolean
  sentEmailCode?: boolean
  twoFactorSuccessAction?: (token: string) => void
  onCloseAction?: () => void
}

export function EmailTwoFactorModal(props: EmailTwoFactorModalProps) {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  return (
    <Modal
      isOpen={isOpen}
      onClose={async () => {
        props.onCloseAction && props.onCloseAction()
        onClose()
      }}
      closeOnOverlayClick={!!props.enrollment ? false : true}
      closeOnEsc={!!props.enrollment ? false : true}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent p="10">
        <ModalHeader>Two-Factor Authentication</ModalHeader>
        {isCompleted && <ModalCloseButton />}
        <ModalBody w="100%">
          <Flex flexDirection="column" w="100%" gap="4">
            <TwoFactorAuthCard
              enrollment={props.enrollment}
              onClose={onClose}
              setIsCompleted={setIsCompleted}
              twoFactorSuccessAction={props.twoFactorSuccessAction}
              sentEmailCode={props.sentEmailCode}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
