import React, { useState, useEffect, useContext, createContext } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client'
import {
  SigninDocument,
  ValidateEmailVerificationCodeDocument,
} from '../operations-types'
import { onError } from '@apollo/client/link/error'
import Sentry from './sentry'
import jwt_decode from 'jwt-decode'

type AuthContext = {
  setAuth: Function
  isSignedIn: Function
  signIn: Function
  signOut: Function
  createApolloClient: Function
  verifyTwoFactorCodeForLogin: Function
  getToken: () => string | null
  getAccountantUserId: () => string | null
  getAdminUserId: () => string | null
}

const authContext = createContext<AuthContext | null>(null)

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = useProvideAuth()

  return (
    <authContext.Provider value={auth}>
      <HelmetProvider>
        <ApolloProvider client={auth.createApolloClient()}>
          {children}
        </ApolloProvider>
      </HelmetProvider>
    </authContext.Provider>
  )
}

export const useAuth = (): AuthContext => {
  return useContext(authContext) as AuthContext
}

function useProvideAuth(): AuthContext {
  const [authToken, setAuthToken] = useState<string | null>(null)

  useEffect(() => {
    const token = initialize()
    if (token && !isTokenExpired(token)) {
      setAuthToken(token)
    } else {
      signOut()
    }
  }, [])

  const initialize = () => {
    return localStorage.getItem('token')
  }

  const isTokenExpired = (token: string): boolean => {
    try {
      const { expiry }: { expiry: number } = jwt_decode(token)

      if (!expiry) {
        return true
      }
      return Date.now() > expiry
    } catch (error) {
      Sentry.captureException(
        `Error decoding login token: ${JSON.stringify(error)}`,
      )
      return true
    }
  }

  const isSignedIn = () => {
    if (authToken) {
      return true
    } else {
      return false
    }
  }

  const getAuthHeaders = () => {
    if (!authToken) return undefined

    return {
      authorization: `Bearer ${authToken}`,
    }
  }

  const createApolloClient = () => {
    const httpLink = createHttpLink({
      uri: '/api/graphql',
      headers: getAuthHeaders(),
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          Sentry.captureException(error.message)
        })
      }

      if (networkError) {
        Sentry.captureException(networkError)
      }
    })

    return new ApolloClient({
      link: from([errorLink, httpLink]),
      cache: new InMemoryCache(),
    })
  }

  const signIn = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => {
    const client = createApolloClient()

    const result = await client.mutate({
      mutation: SigninDocument,
      variables: { email, password },
    })

    if (result?.data?.login?.error) {
      return {
        error: result?.data?.login?.error.message,
      }
    }

    if (result.data?.login?.twoFactorRequired) {
      localStorage.setItem('email', email)
      return {
        twoFactorRequired: true,
      }
    } else if (result?.data?.login?.token) {
      setAuthToken(result.data.login.token)
      localStorage.setItem('token', result.data.login.token)
    } else {
      Sentry.captureException(
        `No token returned from login: ${JSON.stringify(result)}`,
      )
    }
  }

  const verifyTwoFactorCodeForLogin = async ({
    email,
    code,
  }: {
    email: string
    code: string
  }) => {
    const client = createApolloClient()

    const result = await client.mutate({
      mutation: ValidateEmailVerificationCodeDocument,
      variables: {
        email,
        code,
        login: true,
      },
    })

    if (result?.data?.validateEmailVerificationCode?.error) {
      return {
        error: result?.data?.validateEmailVerificationCode?.error.message,
      }
    }

    if (result?.data?.validateEmailVerificationCode?.data?.token) {
      setAuthToken(result.data.validateEmailVerificationCode.data.token)
      localStorage.setItem(
        'token',
        result.data.validateEmailVerificationCode.data.token,
      )
      localStorage.removeItem('email')
    } else {
      Sentry.captureException(
        `No token returned from login: ${JSON.stringify(result)}`,
      )
      return {
        error: 'An error occurred. Please try again.',
      }
    }
  }

  const signOut = () => {
    setAuthToken(null)
    localStorage.removeItem('token')
    localStorage.removeItem('email')
  }

  const setAuth = (token: string) => {
    setAuthToken(token)
    localStorage.setItem('token', token)
  }

  const getToken = () => localStorage.getItem('token')

  const getAccountantUserId = () => {
    const token = getToken()

    if (!token) {
      return null
    }

    const {
      accountantUserId,
    }: {
      accountantUserId: string | undefined
    } = jwt_decode(token)

    return accountantUserId ?? null
  }

  const getAdminUserId = () => {
    const token = getToken()

    if (!token) {
      return null
    }

    const {
      adminId,
    }: {
      adminId: string | undefined
    } = jwt_decode(token)

    return adminId ?? null
  }

  return {
    setAuth,
    isSignedIn,
    signIn,
    verifyTwoFactorCodeForLogin,
    signOut,
    createApolloClient,
    getToken,
    getAccountantUserId,
    getAdminUserId,
  }
}
