import { ApolloError, QueryResult } from '@apollo/client'
import { Payment } from '../types'
import Sentry from './sentry'
import {
  BankAccount,
  DownloadInvoiceQuery,
  Exact,
  PaymentMethod,
  PayoutMethod,
  VendorPayoutMethod,
} from '../operations-types'
import { TransactionStatusCell, TypeTransactionStatus } from 'ui'
import { Buffer } from 'buffer'

export type PaymentMethodIdentifier = {
  type: 'creditCard' | 'bankAccount' | 'checkDeposit' | 'unknown'
  identifier: string
}

export const getPaymentMethodIdentifier = (
  paymentMethod: Payment['paymentMethod'],
  check?: boolean,
): PaymentMethodIdentifier => {
  if (!paymentMethod) {
    return {
      type: 'unknown',
      identifier: 'N/A',
    }
  }

  if (paymentMethod?.type === 'card') {
    return {
      type: 'creditCard',
      identifier: `·· ${paymentMethod?.card?.last4?.slice(2, 4)}`,
    }
  }

  return {
    type: check ? 'checkDeposit' : 'bankAccount',
    identifier: `·· ${paymentMethod?.achDebit?.last2}`,
  }
}

type PayoutMethodIdentifier = Pick<
  PayoutMethod,
  'accountNumberLastTwo' | 'bankName'
>

type VendorPayoutMethodIdentifier = Pick<
  VendorPayoutMethod,
  'accountNumber' | 'bankName'
>

type BankAccountIdentifier = Pick<BankAccount, 'lastTwo' | 'bankName'>

export const getBankAccountIdentifier = (
  payoutMethod: BankAccountIdentifier | null,
) => {
  if (!payoutMethod) {
    return {
      type: 'unknown',
      name: 'N/A',
      identifier: 'N/A',
    }
  }

  return {
    type: 'bankAccount',
    name: payoutMethod?.bankName || '',
    identifier: `·· ${payoutMethod?.lastTwo}`,
  }
}

export const getPayoutMethodIdentifier = (
  payoutMethod: PayoutMethodIdentifier | null,
) => {
  if (!payoutMethod) {
    return {
      type: 'unknown',
      name: 'N/A',
      identifier: 'N/A',
    }
  }

  return {
    type: 'bankAccount',
    name: payoutMethod?.bankName || '',
    identifier: `·· ${payoutMethod?.accountNumberLastTwo}`,
  }
}

export const getVendorPayoutMethodIdentifier = (
  payoutMethod: VendorPayoutMethodIdentifier | null,
) => {
  if (!payoutMethod) {
    return {
      type: 'unknown',
      name: 'N/A',
      identifier: 'N/A',
    }
  }

  return {
    type: 'bankAccount',
    name: payoutMethod?.bankName || '',
    identifier: `${payoutMethod?.bankName} ·· ${payoutMethod?.accountNumber
      ?.toString()
      .slice(-2)}`,
  }
}

type PaymentMethodPayoutIdentifier = Pick<
  PaymentMethod,
  'card' | 'achDebit' | 'type' | 'name' | 'companyName'
>

export type PaymentMethodPayoutPresenter = {
  type: 'creditCard' | 'bankAccount' | 'unknown'
  name: string
  identifier: string
}

export const getPaymentMethodPayoutIdentifier = (
  paymentMethod: PaymentMethodPayoutIdentifier | null,
): PaymentMethodPayoutPresenter => {
  if (!paymentMethod) {
    return {
      type: 'unknown',
      name: 'N/A',
      identifier: 'N/A',
    }
  }

  if (paymentMethod?.type === 'card') {
    return {
      type: 'creditCard',
      name:
        paymentMethod?.companyName ||
        paymentMethod.card?.holderName ||
        paymentMethod.name ||
        '',
      identifier: `${paymentMethod?.card?.brand} ·· ${paymentMethod?.card?.last4}`,
    }
  }

  return {
    type: 'bankAccount',
    name: paymentMethod.achDebit?.bankName || paymentMethod.name || '',
    identifier: `${paymentMethod?.achDebit?.bankName} ·· ${paymentMethod?.achDebit?.last2}`,
  }
}

export const reportErrorIfExists = (
  error?: ApolloError | undefined | string | null,
) => {
  if (error) {
    Sentry.captureException(error)
  }
}

export const getTransactionStatusFromActivity = (
  status: string,
  flags: string[],
): TransactionStatusCell => {
  let customCompleted =
    status === 'PENDING' ? 'processing' : status.toLowerCase()
  let tooltipText = ''

  if (customCompleted === 'completed') {
    if (flags.includes('REFUND')) {
      tooltipText =
        'This transaction was completed and has an associated refund on it.'
      customCompleted = 'completed_with_refund'
    } else if (flags.includes('RETURN')) {
      tooltipText =
        'This transaction was completed and has an associated return on it.'
      customCompleted = 'completed_with_return'
    } else if (flags.includes('CHARGEBACK')) {
      customCompleted = 'completed_with_return'
      tooltipText =
        'This transaction was completed and has an associated chargeback on it.'
    }
  } else if (customCompleted === 'cancelled') {
    if (flags.includes('RETURN') || flags.includes('CHARGEBACK')) {
      customCompleted = 'cancelled_with_return_or_chargeback'
      tooltipText =
        'This transaction was cancelled and has an associated return on it.'
    } else if (flags.includes('REFUND')) {
      customCompleted = 'cancelled_with_refund'
      tooltipText =
        'This transaction was cancelled and has an associated refund on it.'
    }
  }

  return {
    status: customCompleted as TypeTransactionStatus,
    tooltipText,
  }
}

export const downloadInvoiceFile = async (
  downloadInvoiceHandler: () => Promise<
    QueryResult<
      DownloadInvoiceQuery,
      Exact<{
        questionFormId: string
      }>
    >
  >,
  onComplete: () => void,
  onError: () => void,
) => {
  const data = await downloadInvoiceHandler()

  try {
    var blob = new Blob(
      [
        Buffer.from(
          data.data?.questionForm?.questionForm?.invoice?.invoiceBuffer || '',
          'binary',
        ),
      ],
      {
        type: 'application/pdf',
      },
    )
    var link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    var fileName = 'invoice.pdf'
    link.download = fileName
    link.click()

    if (onComplete) {
      onComplete()
    }
  } catch (e) {
    console.info(e)
    if (onError) {
      onError()
    }
  }
}

export const AWS_BUCKET_ROUTE = `https://s3.amazonaws.com/${
  import.meta.env.VITE_AWS_BUCKET
}/`
