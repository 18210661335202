import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
  Select,
  Spinner,
  Text,
} from '@chakra-ui/react'

import Icon from '../Icons'
import { UnstandardizedRow } from '../Layout/Row'
import Separator from '../Layout/Separator'
import { Button } from '../v3/button/Button'

import type { IconName } from '../v3/Icons'

export type OverlayFooterProps = {
  buttonText: string
  onButtonClick?: () => void
  onReconciliationClick?: (reconciled: boolean) => void
  reconciled?: boolean
  icon?: IconName
  options?: Array<MenuItemProps>
  disabled?: boolean
  buttonLoading?: boolean
}

type ReconciliationButtonProps = {
  reconciled?: boolean
  onReconciliationClick?: (reconciled: boolean) => void
}

export function ReconciliationButton({
  reconciled,
  onReconciliationClick,
}: ReconciliationButtonProps) {
  return (
    <Flex flexDirection={'column'}>
      <Text fontSize="xs" color="gray.500">
        Reconciliation
      </Text>
      <Select
        variant="outline"
        borderColor={'gray.500'}
        focusBorderColor={'gray.500'}
        defaultValue={reconciled ? 'done' : 'not_started'}
        textColor={'gray.500'}
        _hover={{
          borderColor: 'gray.500',
          cursor: 'pointer',
        }}
        _focus={{ borderColor: 'gray.500' }}
        className="border border-gray-500 px-2  font-medium text-gray-500"
        onChange={(e) =>
          onReconciliationClick &&
          onReconciliationClick(e.target.value === 'done')
        }
      >
        <option
          value="not_started"
          className="text-gray-500 relative bottom-[5px]"
        >
          Not Started
        </option>
        <option value="done">Done</option>
      </Select>
    </Flex>
  )
}

export function OverlayFooter({
  buttonText,
  buttonLoading,
  onButtonClick,
  onReconciliationClick,
  options,
  reconciled,
  icon,
  disabled,
}: OverlayFooterProps) {
  return (
    <div>
      <Separator orientation="horizontal" />
      <UnstandardizedRow className="px-8 py-4" between y="center">
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            isDisabled={disabled}
            icon={
              disabled ? (
                <Spinner size={'sm'} />
              ) : (
                <Icon
                  name="ellipsisVertical"
                  size="large"
                  hoverable={true}
                  className="stroke-gray-500"
                />
              )
            }
            _hover={{ cursor: 'pointer', background: 'white' }}
            variant="outline"
          />
          <MenuList>
            {options?.map((props, i) => (
              <MenuItem key={i} {...props} />
            ))}
          </MenuList>
        </Menu>
        <Flex flexDirection={'row'} gap={4} alignItems={'flex-end'}>
          {onReconciliationClick && (
            <ReconciliationButton
              reconciled={reconciled}
              onReconciliationClick={onReconciliationClick}
            />
          )}
          {onButtonClick && (
            <Box>
              <Button
                onClick={onButtonClick}
                variant="outline"
                status="primary"
                iconName={icon}
                iconPosition="left"
                label={buttonText}
                isLoading={buttonLoading}
                size="lg"
              />
            </Box>
          )}
        </Flex>
      </UnstandardizedRow>
    </div>
  )
}

export default OverlayFooter
