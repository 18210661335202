import { Account } from './Account'
import { BankAccount } from './BankAccount'
import { Business } from './Business'
import { Owner } from './Owner'
import {
  RegistrationPage,
  useSelfServiceRegistrationStore,
} from './selfServiceRegistrationStore'
import { useFeatureFlagPayload } from 'posthog-js/react'

export function SelfServiceRegistration() {
  const { setStep, step, email } = useSelfServiceRegistrationStore((state) => ({
    setStep: state.setStep,
    step: state.step,
    email: state.email,
  }))

  const payload = useFeatureFlagPayload('manualACHWhitelist') as string[]
  const allowManualEntry = !!email && payload.includes(email)

  const components: Record<RegistrationPage, React.ReactNode> = {
    email: <Account onNextStep={() => setStep('owner')} />,
    owner: <Owner onNextStep={() => setStep('business')} />,
    business: <Business onNextStep={() => setStep('bankAccount')} />,
    bankAccount: <BankAccount allowManualEntry={allowManualEntry} />,
  }

  return <>{components[step]}</>
}
