import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
  StackDivider,
  Switch,
  useToast,
  VStack,
} from '@chakra-ui/react'
import {
  AddHubSpotEmailDocument,
  ChangePasswordDocument,
  QbCompanyInfoDocument,
  SelfDocument,
  SelfQuery,
  UpdatePaymentSettingsDocument,
} from '../../operations-types'
import * as yup from 'yup'
import { Button, Form, FormButton } from 'ui'
import { useState } from 'react'
import Notification from '../utils/Notification'
import NotificationArea from '../utils/NotificationArea'
import { useMutation, useQuery } from '@apollo/client'
import { InfoCardItem } from './InfoCardItem'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { EmailTwoFactorModal } from '../EmailTwoFactor'
import { useAuth } from '../../lib/auth'
import { useDashboardOutletContext } from '../../lib/outletContext'
import { QBOAuthFlow } from '../erp/QBOAuthFlow'
import Loading from '../utils/Loading'

type User = NonNullable<NonNullable<SelfQuery['user']>['user']>
type Organization = NonNullable<User['organization']>

type OrganizationInfoCardProps = {
  orgName: string
}

type PersonalInfoCardProps = {
  fullName: string
  email: string
  setShowNotif(notif: { message: string; header: string; show: boolean }): void
}

type ReadonlyInputProps = {
  label: string
  value: string
}

const ReadonlyInput = ({ label, value }: ReadonlyInputProps) => (
  <FormControl w="100%">
    <FormLabel variant="small">{label}</FormLabel>
    <Input defaultValue={value} disabled w="100%" />
  </FormControl>
)

const OrganizationInfoCard = (props: OrganizationInfoCardProps) => (
  <Box as="form" borderRadius="lg" w="100%">
    <Stack spacing="5">
      <ReadonlyInput label="Legal Name" value={props.orgName} />
    </Stack>
  </Box>
)

const PasswordFormSchema = yup.object().shape({
  newPassword: yup.string().min(8).required(),
  confirmNewPassword: yup
    .string()
    .min(8)
    .required()
    .oneOf([yup.ref('newPassword')]),
})

const PersonalInfoCard = ({
  fullName,
  email,
  setShowNotif,
}: PersonalInfoCardProps) => {
  const [error, setError] = useState<string | null>(null)

  const validateAmount = (newPassword: string, confirmPassword: string) => {
    if (!newPassword && !confirmPassword) {
      setError(null)
      return
    }

    if (
      (newPassword.length < 8 || confirmPassword.length < 8) &&
      newPassword.length > 0 &&
      confirmPassword.length > 0
    ) {
      setError('Password must be at least 8 characters.')
      return
    }

    if (
      newPassword !== confirmPassword &&
      newPassword.length > 0 &&
      confirmPassword.length > 0
    ) {
      setError("Passwords don't match.")
      return
    }

    setError(null)
  }

  const [changePassword, { loading }] = useMutation(ChangePasswordDocument, {
    onCompleted: () => {
      setError('')
      setShowNotif({
        message: `Successfully changed your password.`,
        header: 'Nice!',
        show: true,
      })
    },
  })

  return (
    <Form
      {...{
        validationSchema: PasswordFormSchema,
        initialValues: {
          newPassword: '',
          confirmNewPassword: '',
        },
        onSubmit: async (values, actions) => {
          changePassword({ variables: { newPassword: values.newPassword } })
          actions.setSubmitting(false)
        },
        onChange: (values) => {
          validateAmount(values.newPassword, values.confirmNewPassword)
        },
        className: 'w-full h-full',
      }}
    >
      {(formik) => (
        <Box borderRadius="lg" w="100%">
          <Stack spacing="5">
            <FormControl id="fullName" w="100%">
              <FormLabel variant="small">Full Name</FormLabel>
              <Input defaultValue={fullName} disabled w="100%" />
            </FormControl>
            <FormControl id="email" w="100%">
              <FormLabel variant="small">Email Address</FormLabel>
              <Input defaultValue={email} disabled w="100%" />
            </FormControl>
            <Stack spacing="6" direction={{ md: 'row' }} align="end">
              <FormControl id="newPassword" w="100%" isInvalid={!!error}>
                <FormLabel variant="small">Update Password</FormLabel>
                <Input
                  placeholder="Enter new password"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.newPassword}
                />
                {error ? <FormErrorMessage>{error}</FormErrorMessage> : ''}
              </FormControl>
              <FormControl id="confirmNewPassword" w="100%" isInvalid={!!error}>
                <Input
                  placeholder="Confirm new password"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.confirmNewPassword}
                />
                {error ? <FormErrorMessage>{error}</FormErrorMessage> : ''}
              </FormControl>
            </Stack>
            <FormButton loading={loading} x="right" label="Save" />
          </Stack>
        </Box>
      )}
    </Form>
  )
}

export default function Profile() {
  const data = useDashboardOutletContext()
  const { getAccountantUserId } = useAuth()

  const { data: userData } = useQuery(SelfDocument, {
    fetchPolicy: 'network-only',
  })

  const user = userData?.user?.user

  const [showNotif, setShowNotif] = useState({
    message: '',
    show: false,
    header: '',
  })

  const paymentSettings = data.organization?.accountInfo?.paymentSettings
  const [updatePaymentSettings] = useMutation(UpdatePaymentSettingsDocument, {
    refetchQueries: [
      {
        query: SelfDocument,
      },
    ],
  })

  const [togglingTwoFactor, setTogglingTwoFactor] = useState(false)

  const [hubSpotEmail, setHubSpotEmail] = useState(
    data.organization?.accountInfo?.hubSpotEmailAlias,
  )

  const [editingHubSpotAlias, setEditingHubSpotAlias] = useState(
    !data.organization?.accountInfo?.hubSpotEmailAlias,
  )
  const [show2FA, setShow2FA] = useState(false)
  const onClose = () => setShowNotif({ message: '', show: false, header: '' })
  const { firstName, lastName, email, organization = {} as Organization } = data
  const isHubSpotEnabled = useFeatureFlagEnabled('isHubSpotEnabled')
  const isAccountantLoggedInAsClient = !!getAccountantUserId()
  const { data: qbData, refetch, loading } = useQuery(QbCompanyInfoDocument)
  const hasQbData =
    !!qbData?.organization?.organization?.accountInfo?.qbCompanyInfo?.companyId
  const automaticQbInvoiceSend = paymentSettings?.automaticQbInvoiceEmail
  const quickbooksInfo =
    qbData?.organization?.organization?.accountInfo?.qbCompanyInfo
  const [addHubSpotEmail] = useMutation(AddHubSpotEmailDocument, {
    refetchQueries: [
      {
        query: SelfDocument,
      },
    ],
  })

  const toast = useToast()

  // TODO(claudio.wilson): Move NotificationArea and Notification into a Provider
  return (
    <Box py={{ base: '4', md: '8' }}>
      <NotificationArea>
        <Notification
          show={showNotif['show']}
          onClose={onClose}
          header={showNotif['header']}
          message={showNotif['message']}
        />
      </NotificationArea>
      <Stack spacing="8" divider={<StackDivider />}>
        <InfoCardItem
          label="Company Information"
          sublabel="Information related to your company"
        >
          <OrganizationInfoCard orgName={organization?.name || 'N/A'} />
        </InfoCardItem>
        {!isAccountantLoggedInAsClient && (
          <InfoCardItem
            label="Account Information"
            sublabel="Information related to your account"
          >
            <PersonalInfoCard
              fullName={`${firstName} ${lastName}`}
              email={email || 'N/A'}
              setShowNotif={setShowNotif}
            />
          </InfoCardItem>
        )}

        <InfoCardItem
          label="Require two-factor authentication on login"
          sublabel="If enabled you will be required to enter a code sent to your email on every login."
        >
          <Switch
            size="lg"
            isChecked={!!user?.twoFactorRequired}
            onChange={() => {
              setTogglingTwoFactor(true)
              setShow2FA(true)
            }}
          />

          {show2FA && togglingTwoFactor && (
            <EmailTwoFactorModal
              enrollment={false}
              twoFactorSuccessAction={async (token: string) => {
                const response = await updatePaymentSettings({
                  variables: {
                    automaticQbInvoiceEmail:
                      !!paymentSettings?.automaticQbInvoiceEmail,
                    achEnabled: !!paymentSettings?.achEnabled,
                    feePassthroughPercent:
                      paymentSettings?.feePassthroughPercent ?? 0,
                    creditCardEnabled: !!paymentSettings?.creditCardEnabled,
                    debitCardEnabled: !!paymentSettings?.debitCardEnabled,
                    paymentAmountEditable:
                      !!paymentSettings?.paymentAmountEditable,
                    twoFactorRequired: !user?.twoFactorRequired,
                  },
                })

                if (response.data?.updatePaymentSettings?.error?.message) {
                  toast({
                    title: 'Error',
                    description: `There was an error toggling two-factor authentication: ${response.data.updatePaymentSettings.error.message}`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                  })
                } else {
                  setShow2FA(false)
                  setTogglingTwoFactor(false)
                }
              }}
              onCloseAction={() => {
                setShow2FA(false)
                setTogglingTwoFactor(false)
              }}
            />
          )}
        </InfoCardItem>

        <InfoCardItem
          label="Accounting Sync"
          sublabel={`Sync ${organization?.name}'s Nickel account with QuickBooks`}
        >
          <Stack w="100%" gap={4}>
            {loading && <Loading />}
            {hasQbData && !loading && (
              <>
                <HStack gap={4}>
                  <ReadonlyInput
                    label="Quickbooks Company Id"
                    value={
                      qbData?.organization?.organization?.accountInfo
                        ?.qbCompanyInfo?.companyId || 'N/A'
                    }
                  />
                  <ReadonlyInput
                    label="Quickbooks Company Name"
                    value={
                      qbData?.organization?.organization?.accountInfo
                        ?.qbCompanyInfo?.companyName || 'N/A'
                    }
                  />
                </HStack>
                {quickbooksInfo?.chartOfAccountName && (
                  <ReadonlyInput
                    label="QuickBooks Payout Method Account"
                    value={quickbooksInfo.chartOfAccountName}
                  />
                )}
                <Checkbox
                  size="sm"
                  isChecked={!!automaticQbInvoiceSend}
                  onChange={(checked) => {
                    updatePaymentSettings({
                      variables: {
                        automaticQbInvoiceEmail: checked.target.checked,
                        achEnabled: !!paymentSettings?.achEnabled,
                        feePassthroughPercent:
                          paymentSettings?.feePassthroughPercent ?? 0,
                        creditCardEnabled: !!paymentSettings?.creditCardEnabled,
                        debitCardEnabled: !!paymentSettings?.debitCardEnabled,
                        paymentAmountEditable:
                          !!paymentSettings?.paymentAmountEditable,
                        twoFactorRequired: !!user?.twoFactorRequired,
                      },
                    })
                  }}
                >
                  Automatically send a Nickel payment link when an invoice is
                  created in QuickBooks
                </Checkbox>
              </>
            )}
            <Flex
              justifyContent={hasQbData || loading ? 'flex-end' : 'flex-start'}
            >
              <QBOAuthFlow
                alreadyConnected={hasQbData}
                onFinish={() => {
                  refetch()
                  setShowNotif({
                    message: `Successfully synced QuickBooks.`,
                    header: 'Nice!',
                    show: true,
                  })
                }}
              />
            </Flex>
          </Stack>
        </InfoCardItem>
        {isHubSpotEnabled && !data.hasHubSpotToken && (
          <InfoCardItem
            label="HubSpot Sync"
            sublabel={`Sync ${organization?.name}'s Nickel account with HubSpot`}
          >
            <Stack w="100%" gap={4}>
              <Flex justifyContent="flex-start">
                <Button
                  label="Sync with HubSpot"
                  onClick={() => {
                    window.open(
                      'https://app.hubspot.com/oauth/authorize?client_id=c71fdea5-b677-44a5-884c-79b5cb553844&redirect_uri=https://app.getnickel.com/dashboard/hubspot-authenticated&scope=crm.objects.users.read%20crm.objects.deals.read%20crm.objects.deals.write',
                    )
                    setShowNotif({
                      message: `Successfully synced HubSpot.`,
                      header: 'Nice!',
                      show: true,
                    })
                  }}
                />
              </Flex>
            </Stack>
          </InfoCardItem>
        )}
        {isHubSpotEnabled && data.hasHubSpotToken && (
          <InfoCardItem
            label="HubSpot Sync"
            sublabel={`Sync ${organization?.name}'s Nickel account with HubSpot`}
          >
            <HStack gap={4} w="50%" alignContent="start">
              <VStack w="50%">
                <ReadonlyInput
                  label="Hubspot Connection Status"
                  value="Connected"
                />
              </VStack>
              {editingHubSpotAlias ? (
                <VStack
                  w="100%"
                  alignItems="start"
                  justifyContent="start"
                  spacing="0"
                  gap="0"
                >
                  <FormLabel fontSize="xs" fontWeight="medium" color="gray.500">
                    HubSpot Email Bcc Alias
                  </FormLabel>
                  <HStack>
                    <Input
                      name="hubSpotEmail"
                      placeholder="Enter HubSpot Bcc Email Alias"
                      onChange={(e) => {
                        setHubSpotEmail(e.target.value)
                      }}
                    />
                    <Button
                      size="sm"
                      label="Submit"
                      variant="ghost"
                      onClick={async () => {
                        await addHubSpotEmail({
                          variables: {
                            email: hubSpotEmail || '',
                          },
                        })
                        setEditingHubSpotAlias(false)
                      }}
                    />
                  </HStack>
                </VStack>
              ) : (
                <HStack>
                  <Box>
                    <ReadonlyInput
                      label="Hubspot Email Bcc Alias"
                      value={hubSpotEmail || ''}
                    />
                  </Box>
                  <Box
                    alignContent="bottom"
                    justifyContent="bottom"
                    alignItems="bottom"
                  >
                    <Button
                      size="sm"
                      variant="ghost"
                      label="Change"
                      alignSelf="bottom"
                      onClick={() => {
                        setEditingHubSpotAlias(true)
                      }}
                    />
                  </Box>
                </HStack>
              )}
            </HStack>
          </InfoCardItem>
        )}
      </Stack>
    </Box>
  )
}
