import { useMutation } from '@apollo/client'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import {
  BraintreeCardDetailsForm,
  Button,
  Column,
  PaymentFormData,
  Row,
  Separator,
} from 'ui'
import {
  AddSubscriptionDocument,
  UpdateSubscriptionPaymentMethodDocument,
} from '../../../operations-types'
import BraintreeAddressDetails from '../../forms/BraintreeAddressSection'

function getPayButtonText(amountCents: number) {
  const formattedAmount = (amountCents / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return `Pay ${formattedAmount}`
}

type ModalProps = {
  isOpen: boolean
  onClose: () => void
  clientToken: string
  organizationId: string
  submittedAmountInCents: number
  activated: boolean
  subscriptionId: string
  refetch?: () => Promise<void>
  onFinish?: () => Promise<void>
}

const SubscriptionPaymentMethodModal = ({
  isOpen,
  onClose,
  activated,
  clientToken,
  subscriptionId,
  submittedAmountInCents,
  refetch,
  onFinish,
}: ModalProps) => {
  const [hostedFields, setHostedFields] = useState<braintree.HostedFields>()
  const [addSubscriptionPayment] = useMutation(AddSubscriptionDocument)

  const [loading, setLoading] = useState(false)

  const formik = useFormikContext<PaymentFormData>()

  const [paymentNonce, setPaymentNonceToken] = useState('')

  const toaster = useToast()

  const [updateSubscriptionPayment] = useMutation(
    UpdateSubscriptionPaymentMethodDocument,
  )

  useEffect(() => {
    const execute = async () => {
      if (
        formik &&
        formik.values.billingAddress.fullName &&
        formik.values.billingAddress.zipCode &&
        formik.values.billingAddress.streetAddress &&
        formik.values.billingAddress.city &&
        formik.values.billingAddress.state
      ) {
        await hostedFields
          ?.tokenize({
            cardholderName: formik.values.billingAddress.fullName,
            billingAddress: {
              postalCode: formik.values.billingAddress.zipCode,
              streetAddress: formik.values.billingAddress.streetAddress,
              locality: formik.values.billingAddress.city,
              region: formik.values.billingAddress.state,
            },
          })
          .then(async (payload) => {
            setPaymentNonceToken(payload?.nonce || '')
          })
      }
    }

    execute()
  }, [hostedFields, formik, formik?.values])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        w="800px"
        containerProps={{
          paddingTop: '6rem',
        }}
      >
        <ModalBody w="100%" boxShadow="lg" borderRadius="sm" p="6">
          <VStack w="100%" alignContent="center" gap="5">
            <Column wGrow gap="medium">
              <Row x="left" grow>
                <div className="!text-lg font-semibold !w-full py-2 pb-0">
                  Pay for subscription
                </div>
              </Row>
              <Separator orientation="horizontal" className="h-[1px]" />
            </Column>
            <BraintreeCardDetailsForm
              {...{
                clientToken: clientToken,
                onCVVValidityChange: async (valid: boolean) => {
                  await formik.setFieldTouched(
                    'paymentMethodDetails.creditCardCVV',
                  )

                  await formik.setFieldValue(
                    'paymentMethodDetails.creditCardCVV',
                    valid,
                  )
                },
                onCardNumberValidityChange: async (valid: boolean) => {
                  await formik.setFieldTouched(
                    'paymentMethodDetails.creditCardNumber',
                  )
                  await formik.setFieldValue(
                    'paymentMethodDetails.creditCardNumber',
                    valid,
                  )
                },
                onExpiryValidityChange: async (valid: boolean) => {
                  await formik.setFieldTouched(
                    'paymentMethodDetails.creditCardExpirationDate',
                  )

                  await formik.setFieldValue(
                    'paymentMethodDetails.creditCardExpirationDate',
                    valid,
                  )
                },
                setHostedFields,
                formik,
                hostedFields,
                setPaymentNonceToken,
              }}
            />
            <BraintreeAddressDetails />
            <Button
              id="pay-button"
              type="submit"
              isLoading={loading}
              isDisabled={
                formik &&
                Object.keys(formik.errors).filter(
                  (g) => formik.errors[g] !== '',
                ).length >= 2
              }
              status={'primary'}
              className="w-full"
              onClick={async (e) => {
                formik.setSubmitting(true)
                e.preventDefault()
                setLoading(true)

                if (activated) {
                  await updateSubscriptionPayment({
                    variables: {
                      merchantPaymentMethodId: paymentNonce, //TODO: Deprecate this
                      subscriptionId: subscriptionId,
                    },
                  }).catch((err) => {
                    toaster({
                      title: err.message,
                      status: 'error',
                    })
                  })
                } else {
                  await addSubscriptionPayment({
                    variables: {
                      merchantPaymentMethodId: paymentNonce, //TODO: Deprecate this
                      planId: import.meta.env.VITE_NICKEL_PLUS_PLAN_ID,
                    },
                  }).catch((err) => {
                    toaster({
                      title: err.message,
                      status: 'error',
                    })
                  })
                }

                if (refetch) {
                  await refetch()
                }

                onClose()
                setLoading(false)

                if (onFinish) {
                  await onFinish()
                }
              }}
              size="lg"
              iconName="wallet"
              iconPosition="left"
              label={getPayButtonText(submittedAmountInCents)}
            />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SubscriptionPaymentMethodModal
