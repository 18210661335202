import { useMutation, useQuery } from '@apollo/client'
import { Card, Divider, Flex, HStack, Text, useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { Button, Icon } from 'ui'
import {
  AddSubscriptionDocument,
  OrganizationBillingDocument,
  UpdateSubscriptionPaymentMethodDocument,
} from '../../../../operations-types'
import {
  PaymentSummaryItem,
  ReviewItem,
} from '../../../ap/components/ReviewSendPayment'
import { useNickelPlansStore } from './NickelPlansStore'
import { PaymentMethod } from '../../../ap/components/SendPaymentRouter'
import { PaymentSummary as UIPaymentSummary } from 'ui'
import currency from 'currency.js'
const TransactionDetails = (props?: {
  selectedSavedPaymentMethod?: PaymentMethod | null | undefined
  amount?: number
  name?: string
}) => {
  let {
    selectedSavedPaymentMethod: fromDashboardSelectedSavedPaymentMethod,
    amountResult,
  } = useNickelPlansStore()

  let selectedSavedPaymentMethod =
    fromDashboardSelectedSavedPaymentMethod || props?.selectedSavedPaymentMethod

  return (
    <Card className="py-8 px-6 w-[540px] max-w-full">
      <Flex flexDirection="column" gap={6}>
        <HStack>
          <Icon name="arrowsRightLeft" className="text-gray-500 stroke-2" />
          <Text fontSize="lg" fontWeight="semibold">
            Transaction Details
          </Text>
        </HStack>

        <Flex flexDirection="column" gap={2}>
          <ReviewItem label="Billing Plan" value={amountResult.reason} />
          <ReviewItem
            label="Cost"
            value={currency(amountResult.submittedAmountCents, {
              fromCents: true,
            }).format()}
          />
          <ReviewItem
            label="Payment Date"
            value={new Date().toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })}
          />
          <ReviewItem
            label="Renewal Date"
            value={new Date(
              new Date().setFullYear(new Date().getFullYear() + 1),
            ).toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })}
          />
        </Flex>
        <Divider orientation="horizontal" />
        <Flex flexDirection="column" gap={2}>
          {fromDashboardSelectedSavedPaymentMethod ? (
            <ReviewItem
              label="Payment Method"
              value={`${
                selectedSavedPaymentMethod?.card
                  ? `${selectedSavedPaymentMethod?.card?.brand} ·· ${selectedSavedPaymentMethod?.card?.last4}`
                  : `${selectedSavedPaymentMethod?.achDebit?.bankName} ·· ${selectedSavedPaymentMethod?.achDebit?.last2}`
              }`}
            />
          ) : (
            ''
          )}
        </Flex>
      </Flex>
    </Card>
  )
}

const PaymentSummary = (props?: {
  sendMoneyAmountResult?: number
  paymentSummary?: UIPaymentSummary
}) => {
  let { paymentSummary } = useNickelPlansStore()

  paymentSummary = paymentSummary || props?.paymentSummary

  return (
    <Card className="py-8 px-6 w-[540px] max-w-full">
      <Flex flexDirection="column" gap={6}>
        <HStack>
          <Icon name="currencyDollar" className="text-gray-500 h-5 w-5" />
          <Text fontSize="lg" fontWeight="semibold">
            Payment Summary
          </Text>
        </HStack>
        <Flex flexDirection="column" gap={2}>
          <PaymentSummaryItem
            label="Amount"
            value={paymentSummary?.subtotalString() || ''}
          />
          {paymentSummary
            ?.feePresenters()
            .map((fee, idx) => (
              <PaymentSummaryItem
                key={idx}
                label={fee.name}
                value={currency(0).format()}
              />
            ))}
          <Divider orientation="horizontal" />
          <PaymentSummaryItem
            label="You'll be charged"
            value={paymentSummary?.subtotalString() || ''}
          />
        </Flex>
      </Flex>
    </Card>
  )
}

const NickelPlansReview = () => {
  const toast = useToast()
  const [addSubscriptionPayment, { error, loading }] = useMutation(
    AddSubscriptionDocument,
    {
      refetchQueries: ['OrganizationBilling'],
      onCompleted: (data) => {
        if (data.addSubscription?.error?.message) {
          toast({
            title: 'Error',
            description: data.addSubscription.error.message,
            status: 'error',
          })
        } else {
          setPage('receipt')
        }
      },
    },
  )
  const [updateSubscriptionPayment] = useMutation(
    UpdateSubscriptionPaymentMethodDocument,
    {
      refetchQueries: ['OrganizationBilling'],
      onCompleted: (data) => {
        if (data.updateSubscriptionPaymentMethod?.error?.message) {
          toast({
            title: 'Error',
            description: data.updateSubscriptionPaymentMethod.error.message,
            status: 'error',
          })
        } else {
          setPage('receipt')
        }
      },
    },
  )
  const { data: organizationBillingData } = useQuery(
    OrganizationBillingDocument,
  )

  const { paymentSummary, setPage, selectedSavedPaymentMethod } =
    useNickelPlansStore()

  const toaster = useToast()

  const activated =
    organizationBillingData?.organization?.organization?.accountInfo
      ?.stagedSubscription?.stagedSubscription?.initiated || false

  const subscriptionId =
    organizationBillingData?.organization?.organization?.accountInfo
      ?.subscription?.subscription?.id || ''

  useEffect(() => {
    if (error) {
      toaster({
        title: error.message,
        status: 'error',
      })
    }
  }, [error])

  return (
    <Flex flexDirection="column" gap={8} className="px-4">
      <TransactionDetails />
      <PaymentSummary />
      <Button
        label={`Confirm and Pay ${currency(
          paymentSummary?.amountWithoutFeeCents() || 0,
          {
            fromCents: true,
          },
        ).format()}`}
        width="100%"
        iconName="wallet"
        iconPosition="right"
        isLoading={loading}
        onClick={async () => {
          if (activated) {
            await updateSubscriptionPayment({
              variables: {
                merchantPaymentMethodId: selectedSavedPaymentMethod?.id || '',
                subscriptionId: subscriptionId,
              },
            }).catch((err) => {
              toaster({
                title: err.message,
                status: 'error',
              })
            })
          } else {
            await addSubscriptionPayment({
              variables: {
                merchantPaymentMethodId: selectedSavedPaymentMethod?.id || '',
                planId: import.meta.env.VITE_NICKEL_PLUS_PLAN_ID,
              },
            }).catch((err) => {
              toaster({
                title: err.message,
                status: 'error',
              })
            })
          }
        }}
      />
    </Flex>
  )
}

export default NickelPlansReview
