import { useLazyQuery, useMutation } from '@apollo/client'
import {
  Card,
  Flex,
  HStack,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import {
  PlaidAccount,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from 'react-plaid-link'
import { useNavigate } from 'react-router-dom'
import { ACHForm, Button, GoBackButton, Icon, VFlex } from 'ui'
import { useAuth } from '../../../lib/auth'
import { GET_PAID_URL, PAY_VENDORS_BILLS_URL } from '../../../lib/urls'
import {
  CreateMerchantAchPaymentMethodDocument,
  CreateMerchantAchPaymentMethodWithPlaidDocument,
  CreatePayoutMethodWithPlaidDocument,
  GetBankRoutingNumberDocument,
  GetBankRoutingNumberQuery,
  OnboardingCreatePayoutMethodDocument,
  PortalGetLinkTokenDocument,
  SignupAccountingFirmDocument,
  SignupDocument,
  UpdateOnboardingInformationDocument,
} from '../../../operations-types'
import { RegistrationLayout } from './RegistrationLayout'
import { useSelfServiceRegistrationStore } from './selfServiceRegistrationStore'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import * as yup from 'yup'

type BankAccountPages = 'account' | 'success' | 'manual'

type AccountProps = {
  onNextStep: () => void
  onExitPlaid: () => void
}

function Account({ onNextStep, onExitPlaid }: AccountProps) {
  const [plaidToken, setToken] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [exitOccurred, setExitOccurred] = useState(false)
  const [getLinkToken] = useMutation(PortalGetLinkTokenDocument)
  const toaster = useToast()
  const { setAuth } = useAuth()
  const {
    setPlaidPublicToken,
    token,
    onboardingInfo,
    firstName,
    lastName,
    dateOfBirth,
    businessType,
    subdomain,
    email,
    password,
    referralCode,
  } = useSelfServiceRegistrationStore((state) => ({
    setPlaidPublicToken: state.setPlaidPublicToken,
    token: state.token,
    onboardingInfo: state.onboardingInfo,
    firstName: state.firstName,
    lastName: state.lastName,
    dateOfBirth: state.dateOfBirth,
    businessType: state.businessType,
    subdomain: state.subdomain,
    email: state.email,
    password: state.password,
    referralCode: state.referralCode,
  }))

  const [signup] = useMutation(SignupDocument)

  const [signupAccountant] = useMutation(SignupAccountingFirmDocument, {
    onError: (error) => {
      toaster({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })
  const [createPayoutMethodWithPlaid] = useMutation(
    CreatePayoutMethodWithPlaidDocument,
    {},
  )

  const [createPaymentMethodWithPlaid] = useMutation(
    CreateMerchantAchPaymentMethodWithPlaidDocument,
  )

  const [updateOnboardingInfomation] = useMutation(
    UpdateOnboardingInformationDocument,
  )

  const signupAccount = async (
    publicToken: string,
    plaidAccount: PlaidAccount,
  ) => {
    if (businessType === 'accounting') {
      const data = await signupAccountant({
        variables: {
          password: password!,
          email: email!,
          firstName: firstName!,
          lastName: lastName!,
          name: onboardingInfo?.businessName!,
          token: token!,
          subdomain: subdomain!,
          referralCode: referralCode,
        },
      })

      if (data.data?.signup?.error?.message || !data.data?.signup?.token) {
        toaster({
          title: 'Error',
          description: data.data?.signup?.error?.message,
          status: 'error',
        })
        setLoading(false)
        return
      }

      // Specifically pass in the token for the just signed up accountant
      const headerContext = {
        headers: {
          Authorization: `Bearer ${data.data.signup.token}`,
        },
      }

      const updateOnboardingPromise = updateOnboardingInfomation({
        variables: {
          onboardingInfo: JSON.stringify({
            ...onboardingInfo,
            dateOfBirth: dateOfBirth,
            legalName: onboardingInfo?.businessName,
            applicationSubmitted: true,
          }),
        },
        context: headerContext,
      })

      const payoutPromise = createPayoutMethodWithPlaid({
        variables: {
          publicToken: publicToken,
          bankAccountId: plaidAccount.id,
        },
        context: headerContext,
      })

      const paymentMethodPromise = createPaymentMethodWithPlaid({
        variables: {
          publicToken: publicToken,
          bankAccountId: plaidAccount.id,
        },
        context: headerContext,
      })

      const [{ data: onboardingData }] = await Promise.all([
        updateOnboardingPromise,
        payoutPromise,
        paymentMethodPromise,
      ])

      if (!onboardingData?.updateOnboardingInformation?.error) {
        setAuth(data.data.signup.token)
        onNextStep()
      } else {
        toaster({
          title: 'Error',
          description:
            onboardingData?.updateOnboardingInformation?.error.message,
          status: 'error',
          duration: 4000,
          isClosable: true,
        })

        setLoading(false)
        return
      }
    } else {
      const { data } = await signup({
        variables: {
          password: password!,
          email: email!,
          firstName: firstName!,
          lastName: lastName!,
          name: onboardingInfo?.businessName!,
          token: token!,
          paymentUrl: subdomain!,
          companyBannerImage: '',
          companyLogoImage: '',
          referralCode: referralCode,
        },
      })

      if (data?.signup?.error?.message || !data?.signup?.token) {
        toaster({
          title: 'Error',
          description: data?.signup?.error?.message,
          status: 'error',
        })

        setLoading(false)
        return
      }

      // Specifically pass in the token for the just signed up accountant
      const headerContext = {
        headers: {
          Authorization: `Bearer ${data.signup.token}`,
        },
      }

      const updateOnboardingPromise = await updateOnboardingInfomation({
        variables: {
          onboardingInfo: JSON.stringify({
            ...onboardingInfo,
            dateOfBirth: dateOfBirth,
            legalName: onboardingInfo?.businessName,
            applicationSubmitted: true,
          }),
        },
        context: headerContext,
      })

      const payoutPromise = createPayoutMethodWithPlaid({
        variables: {
          publicToken: publicToken,
          bankAccountId: plaidAccount.id,
        },
        context: headerContext,
      })

      const paymentMethodPromise = createPaymentMethodWithPlaid({
        variables: {
          publicToken: publicToken,
          bankAccountId: plaidAccount.id,
        },
        context: headerContext,
      })

      const [{ data: onboardingData }] = await Promise.all([
        updateOnboardingPromise,
        payoutPromise,
        paymentMethodPromise,
      ])

      if (!onboardingData?.updateOnboardingInformation?.error) {
        setAuth(data?.signup.token)
        onNextStep()
      } else {
        toaster({
          title: 'Error',
          description:
            onboardingData?.updateOnboardingInformation?.error.message,
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
        setLoading(false)
        return
      }
    }
  }

  const createLinkToken = async () => {
    const tokenResponse = await getLinkToken({
      variables: {
        userId: email!,
      },
    })
    if (!tokenResponse.data?.portalGetLinkToken?.linkToken) {
      toaster({
        title: 'Error',
        description: 'Failed to initialize linking process',
        status: 'error',
      })
    }
    const linkToken = tokenResponse.data?.portalGetLinkToken?.linkToken
    setToken(linkToken!)
  }

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (publicToken, metadata) => {
      setPlaidPublicToken(publicToken)
      const connectedBankAccount = metadata.accounts?.[0]
      await signupAccount(publicToken, connectedBankAccount)
    },
    [],
  )

  const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
    // log onExit callbacks from Link, handle errors
    // https://plaid.com/docs/link/web/#onexit
    console.error(error, metadata)
    setLoading(false)
    setExitOccurred(true)
  }, [])

  const config: PlaidLinkOptions = {
    token: plaidToken,
    onSuccess,
    onExit,
  }

  const { open } = usePlaidLink(config)
  const enablePlaidBackup = useFeatureFlagEnabled('plaidBackupEnabled')

  useEffect(() => {
    if (plaidToken) {
      open()
    }
  }, [plaidToken, open])

  return (
    <RegistrationLayout
      title="Bank Account"
      subtitle="Last step, link your bank account so you can send and receive payments."
      currentPage="bankAccount"
    >
      {loading && (
        <Flex alignContent="center" justifyContent="center">
          <Spinner size="xl" />
        </Flex>
      )}
      {!loading && (
        <VStack gap={4}>
          <Card
            cursor="pointer"
            onClick={async () => {
              setLoading(true)
              await createLinkToken()
            }}
            py={8}
            px={6}
            w="300px"
            boxShadow="medium"
            _hover={{
              borderRadius: 'base',
              borderColor: 'gray.800',
              border: '1px',
              boxShadow: 'outline',
              bg: 'outline 1px',
            }}
          >
            <HStack justifyContent={'center'}>
              <Text fontWeight="medium" fontSize="md">
                Link your Bank Account
              </Text>
            </HStack>
          </Card>
          {exitOccurred && enablePlaidBackup && (
            <Card
              cursor="pointer"
              onClick={async () => onExitPlaid()}
              py={8}
              px={6}
              w="300px"
              boxShadow="medium"
              _hover={{
                borderRadius: 'base',
                borderColor: 'gray.800',
                border: '1px',
                boxShadow: 'outline',
                bg: 'outline 1px',
              }}
            >
              <HStack justifyContent={'center'}>
                <Text fontWeight="medium" fontSize="md">
                  Enter bank details manually
                </Text>
              </HStack>
            </Card>
          )}
        </VStack>
      )}
    </RegistrationLayout>
  )
}

export function Success() {
  const navigate = useNavigate()
  return (
    <RegistrationLayout
      title="Success"
      titleIcon={
        <Icon name="checkCircle" size="large" className={'stroke-green-500'} />
      }
      subtitle="Your account is under review and will be activated within the hour. You will receive an email once your account is activated. In the meantime, you can start setting up bills or requesting payments."
      currentPage="bankAccount"
    >
      <VFlex gap={4}>
        <Button
          label="Pay Bills"
          onClick={() => navigate(PAY_VENDORS_BILLS_URL)}
        />
        <Button
          label="Request Payments"
          onClick={() => navigate(GET_PAID_URL)}
        />
      </VFlex>
    </RegistrationLayout>
  )
}

type ManualACHEntryProps = {
  onGoBack: () => void
  onNextStep: () => void
}

const ManualACHSchema = yup.object({
  bankAccountNumber: yup.string().required(),
  bankRoutingNumber: yup.string().required(),
  confirmedBankAccountNumber: yup
    .string()
    .oneOf([yup.ref('bankAccountNumber'), null], 'Does not match with field!')
    .required('Required'),
  accountType: yup.string().oneOf(['checking', 'savings']).required(),
})

export type ManualACHData = yup.InferType<typeof ManualACHSchema>

function ManualACHEntry({ onGoBack, onNextStep }: ManualACHEntryProps) {
  const toaster = useToast()
  const [loading, setLoading] = useState(false)
  const [search, { data, loading: achLoading }] =
    useLazyQuery<GetBankRoutingNumberQuery>(GetBankRoutingNumberDocument)
  const [signup] = useMutation(SignupDocument)
  const [signupAccountant] = useMutation(SignupAccountingFirmDocument, {
    onError: (error) => {
      toaster({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })
  const [updateOnboardingInfomation] = useMutation(
    UpdateOnboardingInformationDocument,
  )

  const [createOnboardingPayoutMethod] = useMutation(
    OnboardingCreatePayoutMethodDocument,
    {},
  )

  const [createMerchantACHPaymentMethod] = useMutation(
    CreateMerchantAchPaymentMethodDocument,
    {},
  )

  const {
    onboardingInfo,
    token,
    firstName,
    lastName,
    dateOfBirth,
    businessType,
    subdomain,
    email,
    password,
    referralCode,
  } = useSelfServiceRegistrationStore((state) => ({
    token: state.token,
    onboardingInfo: state.onboardingInfo,
    firstName: state.firstName,
    lastName: state.lastName,
    dateOfBirth: state.dateOfBirth,
    businessType: state.businessType,
    subdomain: state.subdomain,
    email: state.email,
    password: state.password,
    referralCode: state.referralCode,
  }))

  const { setAuth } = useAuth()

  return (
    <RegistrationLayout
      title="Bank Account"
      subtitle="Manually enter your bank account information. This will take longer to verify."
      currentPage="bankAccount"
    >
      <Formik<ManualACHData>
        {...{
          initialValues: {} as ManualACHData,
          validationSchema: ManualACHSchema,
          onSubmit: async (values) => {},
        }}
      >
        {({ values, setFieldValue, isValid, errors }) => {
          const singupBusinessForNickel = async () => {
            setLoading(true)

            const { data } = await signup({
              variables: {
                password: password!,
                email: email!,
                firstName: firstName!,
                lastName: lastName!,
                name: onboardingInfo?.businessName!,
                token: token!,
                paymentUrl: subdomain!,
                companyBannerImage: '',
                companyLogoImage: '',
                referralCode: referralCode,
              },
            })

            if (data?.signup?.error?.message || !data?.signup?.token) {
              toaster({
                title: 'Error',
                description: data?.signup?.error?.message,
                status: 'error',
              })

              setLoading(false)
              return
            }

            // Specifically pass in the token for the just signed up accountant
            const headerContext = {
              headers: {
                Authorization: `Bearer ${data.signup.token}`,
              },
            }

            const onboardingPromise = updateOnboardingInfomation({
              variables: {
                onboardingInfo: JSON.stringify({
                  ...onboardingInfo,
                  dateOfBirth: dateOfBirth,
                  legalName: onboardingInfo?.businessName,
                  applicationSubmitted: true,
                }),
              },
              context: headerContext,
            })

            const createOnboardingPayoutMethodPromise =
              createOnboardingPayoutMethod({
                variables: {
                  accountHolder: onboardingInfo?.businessName!,
                  routingNumber: values.bankRoutingNumber,
                  accountNumber: values.bankAccountNumber,
                  accountType: 'checking',
                },
                context: headerContext,
              })

            const createMerchantACHPaymentMethodPromise =
              createMerchantACHPaymentMethod({
                variables: {
                  accountNumber: values.bankAccountNumber,
                  routingNumber: values.bankRoutingNumber,
                  accountType: values.accountType,
                },

                context: headerContext,
              })

            const [{ data: onboardingData }] = await Promise.all([
              onboardingPromise,
              createOnboardingPayoutMethodPromise,
              createMerchantACHPaymentMethodPromise,
            ])

            if (!onboardingData?.updateOnboardingInformation?.error) {
              setAuth(data?.signup.token)
              onNextStep()
            } else {
              toaster({
                title: 'Error',
                description:
                  onboardingData?.updateOnboardingInformation?.error.message,
                status: 'error',
                duration: 4000,
                isClosable: true,
              })
              setLoading(false)
              return
            }
          }

          const signupAccountantForNickel = async () => {
            setLoading(true)
            const { data } = await signupAccountant({
              variables: {
                password: password!,
                email: email!,
                firstName: firstName!,
                lastName: lastName!,
                name: onboardingInfo?.businessName!,
                token: token!,
                subdomain: subdomain!,
                referralCode: referralCode,
              },
            })

            if (data?.signup?.error?.message || !data?.signup?.token) {
              toaster({
                title: 'Error',
                description: data?.signup?.error?.message,
                status: 'error',
              })
              setLoading(false)
              return
            }

            // Specifically pass in the token for the just signed up accountant
            const headerContext = {
              headers: {
                Authorization: `Bearer ${data.signup.token}`,
              },
            }

            const onboardingPromise = updateOnboardingInfomation({
              variables: {
                onboardingInfo: JSON.stringify({
                  ...onboardingInfo,
                  dateOfBirth: dateOfBirth,
                  legalName: onboardingInfo?.businessName,
                  applicationSubmitted: true,
                }),
              },
              context: headerContext,
            })

            const createOnboardingPayoutMethodPromise =
              createOnboardingPayoutMethod({
                variables: {
                  accountHolder: onboardingInfo?.businessName!,
                  routingNumber: values.bankRoutingNumber,
                  accountNumber: values.bankAccountNumber,
                  accountType: 'checking',
                },
                context: headerContext,
              })

            const createMerchantACHPaymentMethodPromise =
              createMerchantACHPaymentMethod({
                variables: {
                  accountNumber: values.bankAccountNumber,
                  routingNumber: values.bankRoutingNumber,
                  accountType: values.accountType,
                },

                context: headerContext,
              })

            const [{ data: onboardingData }] = await Promise.all([
              onboardingPromise,
              createOnboardingPayoutMethodPromise,
              createMerchantACHPaymentMethodPromise,
            ])

            if (!onboardingData?.updateOnboardingInformation?.error) {
              setAuth(data?.signup.token)
              onNextStep()
            } else {
              toaster({
                title: 'Error',
                description:
                  onboardingData?.updateOnboardingInformation?.error.message,
                status: 'error',
                duration: 4000,
                isClosable: true,
              })
              setLoading(false)
              return
            }
          }

          const onClick = () => {
            if (!isValid) {
              return
            }

            if (businessType === 'accounting') {
              signupAccountantForNickel()
            } else {
              singupBusinessForNickel()
            }
          }

          return (
            <Flex width={['340px', '540px']} flexDirection="column" gap={8}>
              <Card px={6} py={8}>
                <ACHForm
                  loading={achLoading}
                  routingNumber={values.bankRoutingNumber || ''}
                  accountNumber={values.bankAccountNumber || ''}
                  confirmedAccountNumber={
                    values.confirmedBankAccountNumber || ''
                  }
                  accountSelectInputName="accountType"
                  bankName={
                    data?.bankRoutingNumber?.bankRoutingNumber?.bankName
                  }
                  onChangeRoutingNumber={(routingNumber: string) => {
                    if (routingNumber.length === 9) {
                      search({
                        variables: {
                          bankRoutingNumber: routingNumber,
                        },
                      })
                    }
                    setFieldValue('bankRoutingNumber', routingNumber)
                  }}
                  onChangeAccountNumber={(accountNumber: string) =>
                    setFieldValue('bankAccountNumber', accountNumber)
                  }
                  onChangeConfirmAccountNumber={(
                    confirmAccountNumber: string,
                  ) =>
                    setFieldValue(
                      'confirmedBankAccountNumber',
                      confirmAccountNumber,
                    )
                  }
                />
              </Card>
              <VFlex gap={4}>
                <Button
                  label="Next"
                  onClick={onClick}
                  isDisabled={!isValid}
                  isLoading={loading}
                />
                <GoBackButton onClick={() => onGoBack()} />
              </VFlex>
            </Flex>
          )
        }}
      </Formik>
    </RegistrationLayout>
  )
}

type BankAccountProps = {
  allowManualEntry?: boolean
}

export function BankAccount({ allowManualEntry }: BankAccountProps) {
  const [bankAccountStep, setBankAccountStep] = useState<BankAccountPages>(
    allowManualEntry ? 'manual' : 'account',
  )

  const component: Record<BankAccountPages, JSX.Element> = {
    account: (
      <Account
        onNextStep={() => setBankAccountStep('success')}
        onExitPlaid={() => setBankAccountStep('manual')}
      />
    ),
    manual: (
      <ManualACHEntry
        onGoBack={() => setBankAccountStep('account')}
        onNextStep={() => setBankAccountStep('success')}
      />
    ),
    success: <Success />,
  }

  return <>{component[bankAccountStep]}</>
}
