import { ApolloError, useMutation } from '@apollo/client'
import {
  AddQuestionFormDocument,
  AddQuestionFormMutationVariables,
  QuestionFormSchema,
  UpdateQuestionFormDocument,
  UpdateQuestionFormMutationVariables,
} from '../../operations-types'

type AddQuestionFormMutationVars = Omit<
  AddQuestionFormMutationVariables,
  'schema'
> & {
  schema: Omit<QuestionFormSchema, '__typename'>
}

type UpdateQuestionFormMutationVars = Omit<
  UpdateQuestionFormMutationVariables,
  'schema'
> & {
  schema: Omit<QuestionFormSchema, '__typename'>
}

type QuestionFormOptions = {
  onCompleted?: () => void
  onError?: (e: ApolloError) => void
}

export const useAddQuestionFormDocument = ({
  onCompleted,
  onError,
}: QuestionFormOptions) => {
  const [mutation] = useMutation(AddQuestionFormDocument, {
    onCompleted,
    onError,
  })

  const performMutation = ({
    variables,
  }: {
    variables: AddQuestionFormMutationVars
  }) => {
    return mutation({
      variables: {
        name: variables.name,
        checkoutPath: variables.checkoutPath,
        achEnabled: variables.achEnabled,
        creditCardEnabled: variables.creditCardEnabled,
        feePassthroughPercent: variables.feePassthroughPercent,
        schema: JSON.stringify(variables.schema),
      },
    })
  }

  return [performMutation]
}

export const useUpdateQuestionFormDocument = ({
  onCompleted,
  onError,
}: QuestionFormOptions) => {
  const [mutation] = useMutation(UpdateQuestionFormDocument, {
    onCompleted,
    onError,
  })

  const performMutation = ({
    variables,
  }: {
    variables: UpdateQuestionFormMutationVars
  }) => {
    return mutation({
      variables: {
        questionFormId: variables.questionFormId,
        name: variables.name,
        checkoutPath: variables.checkoutPath,
        achEnabled: variables.achEnabled,
        creditCardEnabled: variables.creditCardEnabled,
        feePassthroughPercent: variables.feePassthroughPercent,
        schema: JSON.stringify(variables.schema),
        checkoutActive: variables.checkoutActive,
        s3Urls: variables.s3Urls,
      },
    })
  }

  return [performMutation]
}
